import { IonCol, IonGrid, IonImg, IonRow} from "@ionic/react";

import WeddingLogo from "../../../assets/Logo/WeddingLogoNoBg.png";
import Verlobung from "../../../assets/OurPics/Verlobung.jpeg";

import "./weddingsection.css";
import MapComponent from "./LeafFletMap";
import React, { useEffect, useRef } from "react";
import { fadeInToLeft, fadeInToRight } from '../utilities/animation';

const DividerWithDot = () => {
    return (
      <div style = {{width: "100%", display:"flex", justifyContent:"center"}}>
      <div style={{ display: 'flex', alignItems: 'center', width: '99%'}}>
        <div style={{ flex: 1, height: '1px', backgroundColor: '#000' }}></div>
        <div style={{ margin: '0 8px', height: '6px', width: '6px', backgroundColor: '#000', borderRadius: '50%' }}></div>
        <div style={{ flex: 1, height: '1px', backgroundColor: '#000' }}></div>
      </div>
      </div>
    );
  };
  
  export default function WeddingSection(){
      const [rightRef, leftRef] = [useRef(null), useRef(null)];
      const [isVisible, setIsVisible] = React.useState(false);


      useEffect(() => {
        const fadeInRight = fadeInToRight(leftRef);
        const fadeInLeft = fadeInToLeft(rightRef);

            if (isVisible) {
              fadeInRight.play();
              fadeInLeft.play();
            } 
            // else {
            //   fadeOutLeft.play();
            //     fadeOutRight.play();
            // }
        }, [isVisible, rightRef, leftRef]);

        useEffect(() => {
      
          const observer = new IntersectionObserver(
              (entries) => {
                  entries.forEach(entry => {
                      if (entry.target === rightRef.current && entry.isIntersecting) {
                          setIsVisible(1);
                      }
                      if (entry.target === leftRef.current && !entry.isIntersecting) {
                          setIsVisible(0);
                      }
                  });
              },
              {
                  threshold: 0.5
              }
          );
      
          const rRef = rightRef.current;
          const lRef = leftRef.current;
      
          if (rRef) observer.observe(rRef);
          if (lRef) observer.observe(lRef);
      
          return () => {
              if (rRef) observer.unobserve(rRef);
              if (lRef) observer.unobserve(lRef);
          };
      },[leftRef, rightRef]);

    return (
        <IonRow className = "wedding-section">
            <IonCol ref = {rightRef} sizeLg = "9" sizeXs = "12" className = "col-1-textcontainer">
                <IonRow style = {{display:"flex", flexDirection:"column"}}>
                    <IonImg src={WeddingLogo} className = "wedding-logo" alt="Hochzeitslogo" />
                    <h1>The Wedding</h1>
                </IonRow>
                <DividerWithDot />
                    <IonRow style = {{display: "flex", flexDirection: "column"}}>
                      <IonGrid className ="ionGrid-p-InfosWedding">
                        <p>Kesselhaus Kolbermoor</p>
                        <p>An d. Alten Spinnerei 5, 83059 Kolbermoor</p>
                      </IonGrid>
                      <IonGrid className ="ionGrid-p-InfosWedding">
                        <p>Samstag, 20. September 2025</p>
                        <p style = {{color: "res"}}>14:00 Uhr</p>
                      </IonGrid>
                    </IonRow>
                    <IonRow className = "mapcomponent" style = {{width: "100%", justifyContent:"center"}}>
                        <MapComponent />
                    </IonRow>
            </IonCol>
            <IonCol sizeLg = "3" sizeXs = "12" className = "col-2-imagecontainer">
                <IonImg style = {{height: "100%"}} ref = {leftRef} src = {Verlobung} className = "col-2-verlobung-img" alt="Verlobungsbild"/>
            </IonCol>
        </IonRow>
    );
}