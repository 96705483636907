import React from 'react';
import TardisDusk from "../../../assets/Tardis/mainTardis.jpeg";
import FarbpaletteOne from "../../../assets/Farbpalette/fp1.png";
import FarbpaletteTwo from "../../../assets/Farbpalette/fp2.png";
import {  IonCol, IonRow, IonGrid, IonImg } from '@ionic/react';

import './dresscodesection.css';


import { fadeInToLeft, fadeInToRight, fadeOutToLeft, fadeOutToRight } from '../utilities/animation';
import { useEffect, useRef } from 'react';


function openHelp() {
    alert("Dresscode: Dusk\n\nThe dresscode for our wedding is Dusk. This means that we would like you to wear something in the colors of the sunset. This can be anything from a light pink to a dark purple. We are looking forward to seeing all the beautiful outfits!");
}

export default function DresscodeSection() {
    const [rightRef, leftRef] = [useRef(null), useRef(null)];
    const [isVisible, setIsVisible] = React.useState(false);

    useEffect(() => {
        const fadeInRight = fadeInToRight(leftRef);
        const fadeInLeft = fadeInToLeft(rightRef);
        const fadeOutLeft = fadeOutToLeft(rightRef);
        const fadeOutRight = fadeOutToRight(leftRef);

            if (isVisible) {
              fadeInRight.play();
              fadeInLeft.play();
            } 
            else {
              fadeOutLeft.play();
                fadeOutRight.play();
            }
        }, [isVisible, rightRef, leftRef]);

        useEffect(() => {
      
          const observer = new IntersectionObserver(
              (entries) => {
                  entries.forEach(entry => {
                      if (entry.target === rightRef.current && entry.isIntersecting) {
                          setIsVisible(1);
                      }
                      if (entry.target === leftRef.current && !entry.isIntersecting) {
                          setIsVisible(0);
                      }
                  });
              },
              {
                  threshold: 0.5
              }
          );
      
          const rRef = rightRef.current;
          const lRef = leftRef.current;
      
          if (rRef) observer.observe(rRef);
          if (lRef) observer.observe(lRef);
      
          return () => {
              if (rRef) observer.unobserve(rRef);
              if (lRef) observer.unobserve(lRef);
          };
      }, [rightRef, leftRef]);


    return (
        <IonCol className="dresscode-section">
            <IonGrid>

            <IonRow>
                <p className = "p-title-dresscode">Dresscode</p>
            </IonRow>
            <IonRow style = {{justifyContent:"center", height: "100%", alignitems: "center"}}>
                <IonCol ref = {rightRef} size="4" style = {{justifyContent: "center"}}>
                    <h2 style={{ color: "white"}}>Farbpalette</h2>
                    <IonGrid className = "farbpalette-bg">
                    <IonImg className="img-farbpallette" src={FarbpaletteOne} alt="Farbpalette One" />
                    <IonImg className="img-farbpallette" src={FarbpaletteTwo} alt="Farbpalette Two" />
                    </IonGrid>

                </IonCol>
                <IonCol ref = {leftRef} size="8" style  = {{display: "flex", justifyContent: "center", height: "fit-content"}}>
                    <IonImg className="frame-img" src={TardisDusk} alt="Tardis" onClick={openHelp} />
                </IonCol>
            </IonRow>
            </IonGrid>

        </IonCol>
    );
}
